.animate-expand-button.active {
  -webkit-animation: grow 1s ease-in-out 1 both;
  animation: grow 1s ease-in-out 1 both;
  color: transparent;
  background-color: #ff195f !important;
  box-shadow: none !important;
  transition: color 1s;
  z-index: 1000;
}

/**
 * ----------------------------------------
 * animation grow
 * ----------------------------------------
 */

@keyframes grow {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    /* animation-timing-function: ease-out; */
  }
  100% {
    -webkit-transform: scale(2.5);
    transform: scale(2.5);
    -webkit-animation-timing-function: ease-out;
    /* animation-timing-function: ease-out; */
  }
}

@-webkit-keyframes grow {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  100% {
    -webkit-transform: scale(2.5);
    transform: scale(2.5);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}

.animated-button:active {
  -webkit-animation: pressed 1.3s ease-in-out 1 both;
  animation: pressed 1.3s ease-in-out 1 both;
}

/* ----------------------------------------------
 * Generated by Animista on 2020-6-15 16:44:23
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation pressed
 * ----------------------------------------
 */
@-webkit-keyframes pressed {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scale(0.91);
    transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: scale(0.87);
    transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}
@keyframes pressed {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scale(0.91);
    transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: scale(0.87);
    transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}
