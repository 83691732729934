@font-face {
  font-family: 'Lato';
  font-style: 'normal';
  font-weight: 400;
  src: url('./Lato/Lato-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 400;
  src: url('./Lato/Lato-Italic.ttf') format('truetype');
}

@font-face {
  font-family: 'Lato';
  font-style: 'normal';
  font-weight: 600;
  src: url('./Lato/Lato-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'Lato';
  font-style: 'italic';
  font-weight: 600;
  src: url('./Lato/Lato-BoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Lato';
  font-style: 'normal';
  font-weight: 900;
  src: url('./Lato/Lato-Black.ttf') format('truetype');
}

@font-face {
  font-family: 'ArchivalBlack';
  font-style: 'normal';
  font-weight: 400;
  src: url('./Archivo_Black/ArchivoBlack-Regular.ttf') format('truetype');
}
