.loading-screen-enter {
  height: 100px;

  position: absolute;
  top: 100%;
  left: 0;
  bottom: auto;
  right: auto;
  background: #ff195f;
  -webkit-transition: all 1s;
  z-index: 100;
  transition: top 2s;
}
.loading-screen-enter-active {
  width: auto;
  height: 100%;
  right: 0px;
  bottom: 0px;
  top: 0px;
  left: 0px;
  position: fixed;
  background: #ff195f;
}

.loading-screen-enter-done {
  width: auto;
  height: 100%;
  right: 0px;
  bottom: 0px;
  top: 0px;
  left: 0px;
  position: fixed;
  z-index: 5000;
  background: #ff195f;
}

.loading-screen-exit-active {
  background: #ff195f;
  width: auto;
  height: 0;
  right: 0px;
  bottom: 0px;
  top: 0px;
  left: 0px;
  position: fixed;
  z-index: 5000;
}

.loading-screen-exit {
  bottom: 100%;
  height: 0;
  transition: all 1s;
  background: #ff195f;

  /* left: 0;
  -webkit-transition: all 1s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 2s cubic-bezier(0.645, 0.045, 0.355, 1); */
}
.loading-screen-exit-done {
  opacity: 1;
  background: #ff195f;
}
